/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useContext } from "react";
import Fetching from "../../../components/misc/Fetching";
import { TabsAttr } from "../../../components/navigation/TabNav";
import { OnlineClientInterface } from "../../../interfaces/ServerInterfaces";
import EditAddressData from "./EditOnlineAddressData";
import EditOnlinePersonalData from "./EditOnlinePersonalDataContainer";
import EditPhones from "./EditPhones";
import { EditSettingsClient } from "./EditSettingsClient";
import SideNav from "../../../components/misc/SideNav";
import DetailOnlineClientContainerV2 from "../DetailOnlineClientContainerV2";
import useServerEcoCycle from "../../../api/useServerEconomicCycle";
import { BasicType } from "../../../interfaces/InterfacesLocal";
import { ClientContex as ClientContexDetails } from "../../economicCycles/orders/Details";
import { ClientContex } from "../OnlineClientsContainer";
import useServer from "../../../api/useServerMain";

interface EditClientCtxItf {
  client: OnlineClientInterface | null;
  editClient: Function;
  isLoading?: boolean;
}

interface EditComponent {
  clientId: number;
  editClient: Function;
  deleteClient: Function;
  isFetching: boolean;
  isLoading: boolean;
  callback?: (client: OnlineClientInterface) => void;
  wichContextUse?: string;
}

export const EditClientCtx = createContext<Partial<EditClientCtxItf>>({});

const EditOnlineClientContainer = ({
  clientId,
  editClient,
  deleteClient,
  isFetching,
  isLoading,
  callback,
  wichContextUse = "default",
}: EditComponent) => {
  const { denyRoles } = useServer();

  //TabNav ------------------------------------------------------------------------------
  const [currentTab, setCurrentTab] = useState("personalData");

  const tabs: TabsAttr[] = [
    {
      name: "Datos personales",
      current: currentTab === "personalData",
      href: "personalData",
    },
    {
      name: "Dirección",
      current: currentTab === "address",
      href: "address",
    },
    {
      name: "Teléfonos",
      current: currentTab === "phones",
      href: "phones",
    },
    {
      name: "Ordenes asociadas",
      current: currentTab === "order",
      href: "order",
    },
  ];

  if (denyRoles(["AUDITOR"])) {
    tabs.push({
      name: "Ajustes",
      current: currentTab === "settings",
      href: "settings",
    });
  }

  const {
    getMainClientInfo = () => {},
    isFetching: isFetchingData,
    clientOrders = [],
    paginateAssociatedOrders = {},
    getClient,
    clientServer: client,
    isEdit,
    isGetClient,
  } = useContext(
    wichContextUse === "default"
      ? ClientContex
      : wichContextUse === "details"
      ? ClientContexDetails
      : ClientContex
  );

  let isFetchingOrders: boolean = isFetchingData!;

  const {
    setAllOrderState,
    allOrdes,
    editOrder,
    isFetching: fetchingOrder,
    updateAllOrderState,
    TransformPreReceiptIntoReceipt,
  } = useServerEcoCycle();

  useEffect(() => {
    !!getClient && getClient(clientId);
  }, []);

  const [filter] = useState<BasicType>({
    clientId: client?.id as number,
  });

  useEffect(() => {
    console.log(client)
    client?.id &&
      getMainClientInfo!(client?.id as number, filter, setAllOrderState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (isGetClient) return <Fetching className="h-96" />;

  return (
    <div className="grid grid-cols-12 gap-x-5">
      {/* <TabNav action={setCurrentTab} tabs={tabs} /> */}
      <aside className="col-span-2">
        <SideNav action={setCurrentTab} tabs={tabs} />
      </aside>
      <section className="col-span-10 relative">
        {isEdit && <Fetching className="" />}

        <EditClientCtx.Provider value={{ client, editClient, isLoading }}>
          {
            <section
              className={`${currentTab !== "personalData" ? "hidden" : ""}`}
            >
              <EditOnlinePersonalData />
            </section>
          }

          {
            <section className={`${currentTab !== "address" ? "hidden" : ""}`}>
              <EditAddressData />
            </section>
          }
          {currentTab === "phones" && <EditPhones />}
          {
            <section className={`${currentTab !== "order" ? "hidden" : ""}`}>
              <DetailOnlineClientContainerV2
                clientId={client?.id as number}
                dependencies={{
                  getMainClientInfo,
                  isFetchingOrders,
                  clientOrders,
                  isLoading,
                  //@ts-ignore
                  paginateAssociatedOrders,
                  setAllOrderState,
                  allOrdes,
                  editOrder,
                  fetchingOrder,
                  updateAllOrderState,
                  TransformPreReceiptIntoReceipt,
                }}
              />
            </section>
          }
          {currentTab === "settings" && (
            <EditSettingsClient
              client={client as OnlineClientInterface}
              deleteClient={deleteClient}
              isFetching={isFetching}
            />
          )}
        </EditClientCtx.Provider>
      </section>
    </div>
  );
};

export default EditOnlineClientContainer;
